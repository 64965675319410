import { useState, useEffect } from 'react';
import axios from 'axios';

export function useUserData(user, selectedSeason) {
  const [primaryOwnerID1, setPrimaryOwnerID1] = useState('');
  const [primaryOwnerID2, setPrimaryOwnerID2] = useState('');
  const [loading, setLoading] = useState(true);
  const [Admin, setAdmin] = useState('False');

  useEffect(() => {
    const fetchData = async () => {
      try {
        var season = selectedSeason;
        var username = user.username;
        var name = user.attributes.name;
        var useremail = user.attributes.email;
        console.log('season', season);
        var emails_tblid = '2be75a14-f427-11ed-af54-0be32c5c629d';
        var url = 'https://g213s33569.execute-api.us-east-2.amazonaws.com/Prod/GetCloudTblData/2be75a14-f427-11ed-af54-0be32c5c629d';
        const response = await axios.get(url);
        let emailToFind = useremail;
        emailToFind = emailToFind.toLowerCase();
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].Email.toLowerCase() === emailToFind) {
            setPrimaryOwnerID1(response.data[i]['Primary Owner ID (1)']);
            setPrimaryOwnerID2(response.data[i]['Primary Owner ID (2)']);
            setAdmin(response.data[i]['Primary Owner ID (1)']);
            setLoading(false);
            break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedSeason) {
      fetchData();
    }
  }, [selectedSeason, user]);

  useEffect(() => {
    const ctReadyHandler = function (e) {
      let datasetId = e.datasetId;
      let domId = e.domId;
      let editor = e.editor;
      let table = e.table;
      table.columns().every(function () {
        var column = this;
        if (Admin !== 'Admin') {
          if (column.title() == 'pri_owner_id') {
            column.search(primaryOwnerID1);
          }
        }
      });
    };

    document.addEventListener('ct-ready', ctReadyHandler);
    return () => {
      document.removeEventListener('ct-ready', ctReadyHandler);
    };
  }, [primaryOwnerID1, primaryOwnerID2, Admin]);

  return { primaryOwnerID1, primaryOwnerID2, loading, Admin };
}
import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AdminPage from './AdminPage';
import './App.css';
import { CloudTables } from '@cloudtables/react';
import { useUserData } from './useUserDataResponsible';

function App({ signOut, user }) {
  const { primaryOwnerID1, primaryOwnerID2, loading, Admin } = useUserData(user);

  return (
    <header className="App-header">
    {loading ? (
      <div>Loading...</div>
    ) : (
      <CloudTables
      src="https://pe5lg5it79.cloudtables.io/loader/275ec060-2639-11ee-810c-576588c6b979/table/bs5"
      apiKey="hxGx5JMtcElhtjqre0Mly3xj"
    />
    )}
  </header>

  );
}

export default withAuthenticator(App);

// routes.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminPage from './AdminPage';
import ResponsiblePage from './ResponsiblePage';
import PrimaryOwner2Page from './PrimaryOwner2Page';
import { CloudTables } from '@cloudtables/react';

const AppRoutes = ({ loading, Admin }) => {
  return (
    <Routes>
      <Route path="/admin" element={Admin === 'Admin' ? <AdminPage /> : null} />
      <Route path="/responsible" element={<ResponsiblePage />} />
      <Route path="/PrimaryOwner2" element={<PrimaryOwner2Page />} />
      <Route path="/" element={
        <header className="App-header">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <CloudTables
              src="https://pe5lg5it79.cloudtables.io/loader/275ec060-2639-11ee-810c-576588c6b979/table/bs5"
              apiKey="hxGx5JMtcElhtjqre0Mly3xj"
            />
          )}
        </header>
      } />
    </Routes>
  );
};

export default AppRoutes;
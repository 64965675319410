// App.js

import React, { useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './App.css';
import { useUserData } from './useUserData';
import AppRoutes from './routes';
import SeasonSelector from './SeasonSelector';

function App({ signOut, user }) {
  const [selectedSeason, setSelectedSeason] = useState('');
  const { primaryOwnerID1, primaryOwnerID2, loading, Admin, selectedSeasonval } = useUserData(user, selectedSeason);

  const handleSeasonSelect = (season) => {
    setSelectedSeason(season);
  };

  return (
    <Router>
      <div className="container">
        {!selectedSeason ? (
          <SeasonSelector onSeasonSelect={handleSeasonSelect} />
        ) : (
          <>
            <div className="header">
              <div className="userSection">
                <h1 className="mainTitle">Special Olympics {selectedSeason}</h1>
                <h1 className="heading">Hello {user.attributes.email}</h1>
                <button className="signOutBtn" onClick={signOut}>
                  Sign out
                </button>
              </div>
            </div>
            <div className="ownerIds">
              <h2>Primary Owner ID: {primaryOwnerID1} </h2>
              {primaryOwnerID2 !== '' && <h2> ***** ||  Primary Owner ID2: {primaryOwnerID2}</h2>}
            </div>
            <div className="App">
              <nav>
                <ul className="nav-links">
                  <li><Link to="/">Primary Owner</Link></li>
                  {primaryOwnerID2 !== '' && <li><Link to="/PrimaryOwner2">Primary Owner 2</Link></li>}
                  <li><Link to="/responsible">Responsible</Link></li>
                  {Admin === 'Admin' && <li><Link to="/admin">Admin</Link></li>}
                </ul>
              </nav>
              <AppRoutes loading={loading} Admin={Admin} />
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default withAuthenticator(App);
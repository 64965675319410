import React, { useState } from 'react';
import axios from 'axios';
import { Button, Heading, TextField } from '@aws-amplify/ui-react';
import { CloudTables } from '@cloudtables/react';


function SearchPage() {

    return (
        <div className="searchPage">
                        <CloudTables
              src="https://pe5lg5it79.cloudtables.io/loader/2be75a14-f427-11ed-af54-0be32c5c629d/table/bs5"
              apiKey="hxGx5JMtcElhtjqre0Mly3xj"
            />
        </div>
    );
}

export default SearchPage;
